<template>
    <div class="notificationMessage">
        <p
            class="h4 colorGray notificationMessage__secondTitle"
            v-if="notificationCenterTitle"
        >
            {{ notificationCenterTitle }}
        </p>
        <div>
            <div
                class="notificationMessage__item notificationMessage__item--first"
                v-for="item in notifications"
                :key="item.notificationMessage"
            >
                <p class="title1 notificationMessage__itemHeader">
                    <span>{{ item.type }}</span>
                    <span>{{ item.date }}</span>
                </p>
                <p
                    class="notificationMessage__itemText"
                    v-if="item.notificationMessage"
                    @click="() => onShowText(item.id)"
                >
                    <span class="notificationMessage__itemTextRow">
                        <img
                            :class="
                                `${
                                    item.isRead
                                        ? 'hidden'
                                        : 'notificationMessage__itemText_icon'
                                }`
                            "
                            v-bind:src="UnreadIcon"
                        />
                        {{
                            isSubstrText && item.id === selectedId
                                ? item.notificationMessage
                                : item.notificationMessage.length > 70
                                ? `${item.notificationMessage.substr(0, 70)}...`
                                : item.notificationMessage
                        }}
                    </span>
                    <router-link to="/study/lesson">
                        <button
                            :class="
                                `btn-text ${
                                    isSubstrText && item.id === selectedId
                                        ? 'notificationMessage__itemText_button'
                                        : 'hidden'
                                }`
                            "
                            v-if="item.notificationMessageButtonLink"
                        >
                            {{ item.notificationMessageButtonLink }}
                        </button>
                    </router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import UnreadIcon from "@/assets/img/common/unreadIcon.svg";
export default {
    name: "NotificationMessage",

    components: {},

    props: {
        notificationCenterTitle: String,
        notifications: Array,
        notificationMessage: String,
        notificationMessageButton: String,
        notificationMessageButtonLink: String,
        isRead: Boolean,
    },
    data() {
        return {
            UnreadIcon: UnreadIcon,
            isSubstrText: false,
            selectedId: null,
        };
    },
    methods: {
        onShowText(id) {
            if (this.selectedId === id) {
                this.isSubstrText = !this.isSubstrText;
            } else {
                this.selectedId = id;
                this.isSubstrText = true;
            }
        },
    },
};
</script>

<style lang="scss">
@import "./NotificationMessageCenter.scss";
</style>
