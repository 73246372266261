<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/reviews`"
        />

        <div class="pageSection notificationCenter">
            <div class="notificationCenter__sideBar">
                <p
                    :class="
                        this.isVisible === 'MESSAGE1'
                            ? 'colorDenim notificationCenter__sideBar_text'
                            : 'notificationCenter__sideBar_text'
                    "
                    @click="() => handleClickButton('MESSAGE1')"
                >
                    <span>
                        Трейдинг от А до Я за 60 дней 3.5
                    </span>
                    <span class="colorWhite notificationCenter__sideBar_counter">5</span>
                </p>
                <!-- <p
                    :class="
                        this.isVisible === 'MESSAGE2'
                            ? 'colorDenim notificationCenter__sideBar_text'
                            : 'notificationCenter__sideBar_text'
                    "
                    @click="() => handleClickButton('MESSAGE2')"
                >
                    <span>
                        Трейдинг от А до Я за 60 дней 3.0
                    </span>
                    <span class="colorWhite notificationCenter__sideBar_counter">1</span>
                </p>
                <p
                    :class="
                        this.isVisible === 'MESSAGE3'
                            ? 'colorDenim notificationCenter__sideBar_text'
                            : 'notificationCenter__sideBar_text'
                    "
                    @click="() => handleClickButton('MESSAGE3')"
                >
                    <span>
                        Курс активного трейдера 2.0 в Киеве
                    </span>
                    <span class="colorWhite notificationCenter__sideBar_counter">1</span>
                </p>
                <p
                    :class="
                        this.isVisible === 'MESSAGE4'
                            ? 'colorDenim notificationCenter__sideBar_text'
                            : 'notificationCenter__sideBar_text'
                    "
                    @click="() => handleClickButton('MESSAGE4')"
                >
                    Трейдинг: простой старт
                </p> -->
            </div>
            <div class="customScroll backgroundZircon notificationCenter__content">
                <div class="notificationCenter__content_item">
                    <div v-if="isVisible === 'MESSAGE1'">
                        <div
                            class="notificationCenter__content_itemMessage"
                            v-for="item in notificationItems"
                            :key="item.courseTitle"
                        >
                            <NotificationMessageCenter
                                :notificationCenterTitle="item.notificationCenterTitle"
                                :notifications="item.notifications"
                                :notificationMessage="item.notificationMessage"
                            />
                        </div>
                    </div>
                    <div v-if="isVisible === 'MESSAGE2'">
                        <div
                            class="notificationCenter__content_itemMessage"
                            v-for="item in notificationItems2"
                            :key="item.courseTitle"
                        >
                            <NotificationMessageCenter
                                :notificationCenterTitle="item.notificationCenterTitle"
                                :notifications="item.notifications"
                                :notificationMessage="item.notificationMessage"
                            />
                        </div>
                    </div>
                    <div v-if="isVisible === 'MESSAGE3'">
                        <div
                            class="notificationCenter__content_itemMessage"
                            v-for="item in notificationItems3"
                            :key="item.courseTitle"
                        >
                            <NotificationMessageCenter
                                :notificationCenterTitle="item.notificationCenterTitle"
                                :notifications="item.notifications"
                                :notificationMessage="item.notificationMessage"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="notificationCenter__noContent"
                    v-if="isVisible === 'MESSAGE4'"
                >
                    <p class="h4 colorGullGray">
                        На данный момент уведомлений нет
                    </p>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import Button from "@/views/components/Button/Button";
import NotificationMessageCenter from "@/views/components/NotificationMessageCenter/NotificationMessageCenter";

export default {
    name: "notificationCenter",
    components: {
        Layout,
        PageHeader,
        Button,
        NotificationMessageCenter,
    },
    props: {},

    data() {
        return {
            title: "Центр уведомлений",

            isVisible: "MESSAGE1",

            items: [
                {
                    text: "отзывы",
                    to: "/reviews",
                },
                {
                    text: "Центр уведомлений",
                    active: true,
                },
            ],

            notificationItems: [
                {
                    notificationCenterTitle: "Сегодня",
                    notifications: [
                        {
                            id: 1,
                            type: "напоминание",
                            date: "Сейчас",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            isRead: true,
                            notificationMessageButtonLink: "перейти",
                        },
                        {
                            id: 2,
                            type: "уведомление",
                            date: "14:43",
                            notificationMessage: "Куратор Евгений ответил на Ваш запрос.",
                            isRead: true,
                        },
                        {
                            id: 3,
                            type: "напоминание",
                            date: "5 мин назад",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            notificationMessageButtonLink: "перейти",
                        },
                    ],
                },
                {
                    notificationCenterTitle: "31 августа",
                    notifications: [
                        {
                            id: 4,
                            type: "напоминание",
                            date: "Сейчас",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            isRead: true,
                            notificationMessageButtonLink: "перейти",
                        },
                        {
                            id: 5,
                            type: "уведомление",
                            date: "14:43",
                            notificationMessage: "Куратор Евгений ответил на Ваш запрос.",
                        },
                        {
                            id: 6,
                            type: "напоминание",
                            date: "5 мин назад",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            notificationMessageButtonLink: "перейти",
                        },
                    ],
                },

                {
                    notificationCenterTitle: "30 августа",
                    notifications: [
                        {
                            id: 7,
                            type: "напоминание",
                            date: "Сейчас",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            isRead: true,
                            notificationMessageButtonLink: "перейти",
                        },
                        {
                            id: 8,
                            type: "уведомление",
                            date: "14:43",
                            notificationMessage: "Куратор Евгений ответил на Ваш запрос.",
                        },
                        {
                            id: 9,
                            type: "напоминание",
                            date: "5 мин назад",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            notificationMessageButtonLink: "перейти",
                        },
                    ],
                },
            ],

            notificationItems2: [
                {
                    notificationCenterTitle: "Сегодня",
                    notifications: [
                        {
                            id: 1,
                            type: "напоминание",
                            date: "Сейчас",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            notificationMessageButtonLink: "перейти",
                        },
                        {
                            id: 2,
                            type: "уведомление",
                            date: "14:43",
                            notificationMessage: "Куратор Евгений ответил на Ваш запрос.",
                            isRead: true,
                        },
                        {
                            id: 3,
                            type: "напоминание",
                            date: "5 мин назад",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            notificationMessageButtonLink: "перейти",
                        },
                    ],
                },
                {
                    notificationCenterTitle: "25 августа",
                    notifications: [
                        {
                            id: 4,
                            type: "напоминание",
                            date: "Сейчас",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            notificationMessageButtonLink: "перейти",
                        },
                    ],
                },

                {
                    notificationCenterTitle: "23 августа",
                    notifications: [
                        {
                            id: 5,
                            type: "напоминание",
                            date: "Сейчас",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            notificationMessageButtonLink: "перейти",
                        },
                    ],
                },
            ],

            notificationItems3: [
                {
                    notificationCenterTitle: "Сегодня",
                    notifications: [
                        {
                            id: 1,
                            type: "напоминание",
                            date: "Сейчас",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            notificationMessageButtonLink: "перейти",
                            isRead: true,
                        },
                        {
                            id: 2,
                            type: "уведомление",
                            date: "14:43",
                            notificationMessage: "Куратор Евгений ответил на Ваш запрос.",
                            isRead: true,
                        },
                    ],
                },
                {
                    notificationCenterTitle: "25 августа",
                    notifications: [
                        {
                            id: 3,
                            type: "напоминание",
                            date: "Сейчас",
                            notificationMessage:
                                "Завтра в 18:00 окрывается дополнительное задание. Пройдите все уроки и задания, чтобы иметь возможность ответить на задание от Александра Герчика. ",
                            notificationMessageButtonLink: "перейти",
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        handleClickButton(value) {
            this.isVisible = value;
        },
    },
};
</script>

<style lang="scss">
@import "./notificationCenter.scss";
</style>
